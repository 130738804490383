// src/locales/messages.js
const messages = {
  en: {
    IfcEntity: "Entity Type",
    Actor_type: "Actor Type",
    humidity: "Humidity (%)",
    temperature: "Temperature (°C)",
    crackMeasurement1: "Crack Measurement 1 (mm)",
    crackMeasurement2: "Crack Measurement 2 (mm)",
    raisedDate: "Raised On",
    LayerThickness: "Layer Thickness (mm)",
    EstimatedAmount: "Estimated Amount (kg)",
    PlannedAmount: "Planned Amount (kg)",
    RealAmount: "Real Amount (kg)",
    plannedTime: "Planned Time (hr)",
    realTime: "Real Time (hr)",
    GsmSignal: "GSM Signal (%)",
    MaxAccelerationX: "Max Acc X (m/s²)",
    MaxAccelerationY: "Max Acc Y (m/s²)",
    MaxAccelerationZ: "Max Acc Z (m/s²)",
    MedianAccelerationX: "Median Acc X (m/s²)",
    MedianAccelerationY: "Median Acc Y (m/s²)",
    MedianAccelerationZ: "Median Acc Z (m/s²)",
    MinAccelerationX: "Min Acc X (m/s²)",
    MinAccelerationY: "Min Acc Y (m/s²)",
    MinAccelerationZ: "Min Acc Z (m/s²)",
    PowerinVoltage: "Powerin Voltage (V)",
    angleX: "Angle X (°)",
    angleY: "Angle Y (°)",
    angleZ: "Angle Z (°)",
    name: "Name",
    Materials: "Materials",
    materials: "Materials",
    currentTask: "Current Task",
    location: "Location",
    description: "Description",
    status: "Status",
    positionX: "Position X",
    positionY: "Position Y",
    positionZ: "Position Z",
    type: "Type",
    Actor: "Actor",
    Job: "Job",
    Anomaly_image_path: "Anomaly image path",
    anomaly: "Anomaly",
    SensorBox: "Sensor Box",
    Concrete: "Concrete",
    ConcreteRecipe: "ConcreteRecipe",
    material: "Material",
    quantity: "Quantity",
    unit: "Unit",
    strength: "Strength",
    dataDate: "Data timestamp",
    phase: "Phase",
    severity: "Severity",
    message: "Message",
    alertId: "Alert Id",
    actions: "Alert Actions",
    responsible: "Responsible",
    readingTime: "Reading Time",
    sensor: "Sensor",
    version: "Version",
    sourceId: "ID at the data source",
    itemId: "Item ID",
    id: "ID",
    robot: "Robot",
    projectId: "Project Id",
    role: "Role",
    value: "Value",
    _id: "ID",
    key: "Key",
    closed: "Closed",
    cancelled: "Cancelled",
    pump: "Pump",
    hose: "Hose"
  },
};

export default messages;
