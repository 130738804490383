<template>
    <div id="nav-btn">
        <transition name="nav-trans">
            <div @click="fnClick" v-show="show" 
                 class="block mt-4 lg:inline-block lg:mt-0 hover:cursor-pointer mr-4 py-2 px-3 font-mono shadow-sm rounded-2xl bg-indigo-400 text-white">
                 {{ name }}
      </div>
        </transition>
        <div @click="fnClick" v-show="!show" 
             class="block mt-4 lg:inline-block lg:mt-0 hover:cursor-pointer mr-4 py-2 px-3 font-mono text-slate-500  hover:rounded-2xl hover:bg-indigo-100 ">
             {{ name }}
        </div>
    </div>
</template>
<script>
    export default {
        name: "NavButtonComponent",
        emits: ["navClick"],
        props: {
            show: Boolean,
            name: String
        },
        methods:
        {
            fnClick(event) {
                this.$emit('navClick', event);
            }
        }
    };
</script>
<style scoped>
    .nav-trans-enter-active {
        transition: all 0.2s ease-in;
    }

    .nav-trans-enter-from {
        transform: translateY(20px);
        opacity: 0;
    }
</style>
