<template>
    <h2 class="text-4xl leading-tight text-center m-auto text-bold">Incident Manager</h2>
    <div class="flex align-middle px-5">        
        <Button type="button" label="INCIDENT" icon="pi pi-plus-circle" iconPos="right"
                class="ml-auto" title="Add a new Incident" @click="fnAddNew"></Button>
    </div>
    <div class="px-5 py-3 flex-auto">
        <div class="border-2 p-2 shadow-md table-widget rounded-md border-indigo-400">
            <h3 class="text-indigo-600 text-center text-xl"
                style="text-transform: uppercase; font-weight: bold">
                Incidents
            </h3>
            <DataTable :value="tableData" stripedRows class="w-full" size="small"
                       paginator :rows="10" :rowsPerPageOptions="[10, 20, 30, 40, 50, 100]"
                        sortField="timestamp" :sortOrder="-1"
                       v-model:filters="filters" filterDisplay="row" :loading="isLoading"
                       :globalFilterFields="filterFields">
                <!--<template #header>
        <div class="flex justify-content-end">
            <IconField iconPosition="left">
                <InputIcon>
                    <i class="pi pi-search" />
                </InputIcon>
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" size="small" />
            </IconField>
        </div>
    </template>-->
                <template #empty>
                    No incidents found.
                </template>
                <template #loading>
                    Loading Incidents data. Please wait...
                </template>
                <!-- issueNumber -->
                <Column :exportable="false" bodyClass="dark:text-surface-200">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" outlined rounded size="small" title="Edit"
                                class="dark:text-primary-300 dark:border-primary-300"
                                @click="fnEdit(slotProps.data.incidentId)" />
                    </template>
                </Column>
                <Column :exportable="false" bodyClass="dark:text-surface-200">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" outlined rounded severity="danger" size="small" title="Delete"
                                class="dark:text-red-300 dark:border-red-300"
                                @click="fnOpenConfBox(slotProps.data.incidentId, slotProps.data.title)" />
                    </template>
                </Column>
                <Column field="issueNumber" header="Issue #" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ data.issueNumber }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-[6rem]" placeholder="Search" size="small" />
                    </template>
                </Column>
                <!-- title -->
                <Column field="title" header="Title" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ data.title }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-[10rem]" placeholder="Search" size="small" />
                    </template>
                </Column>
                <!-- category -->
                <Column field="category" header="Category" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ data.category }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-[10rem]" placeholder="Search" size="small" />
                    </template>
                </Column>
                <!-- priority -->
                <Column field="priority" header="Priority" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ data.priority }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-[8rem]" placeholder="Search" size="small" />
                    </template>
                </Column>
                <!-- status -->
                <Column field="status" header="Status" sortable bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ data.status }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <InputText v-model="filterModel.value" type="text" @input="filterCallback" class="w-[8rem]" placeholder="Search" size="small" />
                    </template>
                </Column>
                <!-- raisedDate -->
                <Column field="raisedDate" header="Created Date" sortable dataType="date" bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatDate(data.raisedDate) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Calendar v-model="filterModel.value" dateFormat="dd M yy" placeholder="dd mmm yyyy" mask="99 aaa 9999" @date-select="filterCallback" inputClass="w-[10rem] p-2" />
                    </template>
                </Column>
                <Column field="dueDate" header="Due Date" sortable dataType="date" bodyClass="dark:text-surface-200">
                    <template #body="{ data }">
                        {{ fnFormatDate(data.dueDate) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Calendar v-model="filterModel.value" dateFormat="dd M yy" placeholder="dd mmm yyyy" mask="99 aaa 9999" @date-select="filterCallback" inputClass="w-[10rem] p-2" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
    <loader-component :isLoading="isLoading" type="primary"></loader-component>
    <confirmation-component :msg="confirmDelete.message"
                            :isConfirmationOpen="confirmDelete.isOpen"
                            @close="fnCloseConfBox"
                            @ok="fnDelete"></confirmation-component>
    </template>
<script>

    import LoaderComponent from "@/components/LoaderComponent.vue";
    import ConfirmationComponent from "@/components/ConfirmationComponent.vue";

    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    import Button from 'primevue/button';

    import InputText from 'primevue/inputtext';
    import Calendar from 'primevue/calendar';

    import { ToastSeverity } from 'primevue/api';
    import { FilterMatchMode } from 'primevue/api';

    import { incidentService } from "@/services/IncidentService.js";

    import { capitalizeFirstLetter, formatNumber, formatDate } from "@/utils/utils.js";

    export default {
        name: "IncidentManagerPage",
        components: {
            ConfirmationComponent,
            LoaderComponent,
            DataTable, Column,
            Button, InputText, Calendar
        },
        data() {
            return {
                isLoading: false,
                tableData: [],
                confirmDelete: {
                    message: "",
                    isOpen: false,
                    incidentId: "",
                    entityName: ""
                },
                addSave: {
                    incidentId: undefined,
                    isOpen: false
                },
                connections: [],
                filterFields: ['issueNumber', 'title', 'category', 'type', 'priority', 'status', 'raisedDate'],
                filters: {
                    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    issueNumber: { value: null, matchMode: FilterMatchMode.EQUALS },
                    title: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    category: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    priority: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    status: { value: null, matchMode: FilterMatchMode.CONTAINS },
                    raisedDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
                    dueDate: { value: null, matchMode: FilterMatchMode.DATE_IS }
                },
            };
        },
        created() {
            console.log("Incident Manager: Created");
            this.$store.dispatch("parseHash").
                then(() => {
                    this.fnLoad();
                });
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        unmounted() {
            console.log("Unmounting Incident Manager Page...");

        },
        methods: {
            fnLoad: function () {
                this.isLoading = true;
                this.tableData = [];
                //TODO: Call
                incidentService.find(this.$store.getters.project)
                    .then((data) => {                        
                        
                        //console.log(data);
                        if (data != undefined && Array.isArray(data)) {

                            data.forEach((currentValue) => {
                                //console.log(currentValue["createdAt"]);
                                if (currentValue["timestamp"] !== undefined && currentValue.timestamp.toString().length > 9) {
                                    currentValue["timestamp"] = new Date(currentValue["timestamp"].substring(0, 10));
                                }
                            });
                            this.tableData = data;
                        }
                        //console.log(this.tableData);
                        this.tableLoader = false;
                        this.isLoading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.isLoading = false;
                        this.fnShowToast('Error', 'Error when loading Incidents...!!!', ToastSeverity.ERROR);
                    });
                return;
            },
            fnOnAdd(entityAdded) {
                this.fnShowToast('Save Success', 'The Incident "' + entityAdded.name + '" was inserted!', ToastSeverity.SUCCESS);
                //alert("Update Alert: " + incidentId);
                this.tableData.push(entityAdded);
            },
            fnOnSave(entityNew) {
                this.fnShowToast('Save Success', 'The Incident "' + entityNew.name + '" was updated!', ToastSeverity.SUCCESS);
                //alert("Update Alert: " + incidentId);
                const currentIndex = this.tableData.findIndex(p => p.incidentId === entityNew.incidentId);

                this.tableData.splice(currentIndex, 1, entityNew);
            },
            fnOnDelete(incidentId, entityName) {

                this.fnShowToast('Delete Success', 'The Incident "' + entityName + '" was deleted!', ToastSeverity.WARN);
                //console.log('warning - The alert "' + entityName + '" was deleted!');

                const currentIndex = this.tableData.findIndex(p => p.incidentId === incidentId);
                this.tableData.splice(currentIndex, 1);
            },
            fnDelete() {
                this.loading = true;

                incidentService.delete(this.confirmDelete.incidentId)
                    .then(() => {
                        //.then((data) => {
                        //console.log(data);
                        this.fnOnDelete(this.confirmDelete.incidentId, this.confirmDelete.entityName);

                        this.confirmDelete.message = "";
                        this.confirmDelete.isOpen = false;
                        this.confirmDelete.incidentId = "";
                        this.confirmDelete.entityName = "";

                        this.loading = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.fnShowToast('Error', 'An error occurred when trying to delete the alert "' + this.confirmDelete.entityName + '"!!!', ToastSeverity.ERROR);
                        this.loading = false;
                    });
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnOpenConfBox: function (entityId, entityName) {
                this.confirmDelete.incidentId = entityId;
                this.confirmDelete.entityName = entityName;
                this.confirmDelete.message = "Do you really want to delete the Incident: '" + entityName + "'?";
                this.confirmDelete.isOpen = true;
            },
            fnCloseConfBox() {
                this.confirmDelete.incidentId = "";
                this.confirmDelete.entityName = "";
                this.confirmDelete.message = "";
                this.confirmDelete.isOpen = false;
            },
            fnAddNew() {
                this.$router.push({ name: "incident-details" });
            },
            fnEdit: function (entityId) {
                this.$router.push({
                    name: "incident-details",
                    params: { entityId: entityId }
                });
            },
            fnCloseAlertComp() {
                this.alerComp.isOpen = false;
                this.alerComp.incidentId = "";
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnFormatNumber(number) {
                return formatNumber(number);
            },
            fnFormatDate(dateValue) {
                return formatDate(new Date(dateValue));
            }
        }
    };

</script>
