<template>
    <form id="frmWork" name="frm-work" ref="frmWork">
        <PrimeDialog v-model:visible="visible" modal :header="textHeader" @hide="fnCancel"
                     :draggable="false" :style="{ width: '30rem' }"
                     :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <!-- Working date -->
            <div class="mb-2">
                <div v-if="v$.entity.workDate.$error" class="text-red-600 text-sm mb-2">Please enter a Working date.</div>
                <Calendar v-model="entity.workDate" showIcon iconDisplay="input" placeholder="Working day" inputClass="w-48" 
                          :minDate="minMaintDate" :maxDate="maxMaintDate"/>
                <!-- Worked hours -->
            </div>
            <div>
                <div v-if="v$.entity.workHours.$error" class="text-red-600 text-sm mb-2">
                    Please enter the Hours worked for the asset <br />(a valid positive number greater than 0).
                </div>
                <InputNumber v-model="entity.workHours" placeholder="Worked hours" inputClass="w-48" />
            </div>
            <template #footer>
                <Button label="Cancel" title="Cancel and go back" severity="secondary" class="mr-2"
                        @click="fnCancel" icon="pi pi-times-circle" iconPos="left"></Button>
                <Button label="Add" title="Add working hours" @click="fnDisposeAsset" 
                        icon="pi pi-plus-circle" iconPos="right" />
            </template>
        </PrimeDialog>
    </form>
</template>

<script>
  import Calendar from 'primevue/calendar';
  import Button from 'primevue/button';
  import PrimeDialog from "primevue/dialog";
  import InputNumber from "primevue/inputnumber";
    
  import { ToastSeverity } from 'primevue/api';
  import { useVuelidate } from '@vuelidate/core'
  import { required, minValue, decimal } from '@vuelidate/validators'

  import { assetService } from "@/services/AssetService.js";
  import { capitalizeFirstLetter, dateTimeToString, arrayHasValue } from "@/utils/utils.js";
  import { addMonths } from 'date-fns';

    export default {
        name: "AssetWorkComponent",
        emits: ["onError", "onClose", "onAddWork"],
        setup() {
            return { v$: useVuelidate({ $scope: "work" }) }
        },
        components: {
            Calendar,
            Button,
            PrimeDialog,
            InputNumber
        },
        props: {
            asset: Object,
            isOpen: Boolean
        },
        data() {
            return {
                entity: {
                    entityId: undefined,
                    projectId: undefined,
                    workDate: undefined,
                    workHours: undefined,
                    user: undefined
                },
              textHeader: "",
              minMaintDate: new Date(2024, 0, 1),
              maxMaintDate: addMonths(new Date(), 3),
              visible: false
            }
        },
        validations() {
            return {
                entity: {
                    workDate: { required },
                    workHours: { required, minValue: minValue(1), decimal }
                }
            }
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {                    
                    console.log("Add Working hours Component Opened!");
                    this.entity.entityId = this.asset.assetId;
                    this.entity.projectId = this.$store.getters.project;
                    this.entity.user = this.$store.getters.username;
                    //this.entity.workDate = Date.now();
                    this.visible = this.isOpen;
                  this.textHeader = "Add working hours to '" + this.asset.name + "'";
                  this.minMaintDate = this.fnGetMinDate();
                }
            }
        },
        methods: {
            fnDisposeAsset() {
                this.v$.$validate()
                    .then(result => {
                        //console.log(this.v$.alertObj.name.$errors);
                        //console.log(this.$store.getters.username);
                        let work = {
                            entityId: this.entity.entityId,
                            projectId: this.entity.projectId,
                            workDate: dateTimeToString(this.entity.workDate),
                            workHours: this.entity.workHours,
                            user: this.entity.user
                        };
                        console.log(JSON.stringify(work));
                        if (result) {
                            this.loading = true;
                            this.entity.user = this.$store.getters.username;
                            
                            assetService.addWork(this.entity)
                                .then((data) => {
                                    console.log(data);

                                    //Update component with values.
                                    this.entity.workId = data["upserted_id"];
                                    this.$emit('onAddWork', this.entity);

                                    this.loading = false;
                                    this.fnCancel();
                                })
                                .catch((error) => {
                                    console.log(error);
                                  this.$emit('onError', 'Unexpected Error.', 'An error occurred when trying to dispose the Asset: "' + this.asset.name + '"!!!', ToastSeverity.ERROR);
                                    this.loading = false;
                                });
                        }
                        //else {
                        //    console.log(this.v$.alertObj.name);
                        //}

                        return false;
                    })
                    .catch(error => {
                        console.log(error);
                    });
                
                return false;
            },
            fnCancel() {
                this.fnCleanForm();
                this.visible = false;
                this.textHeader = "";
                this.$emit('onClose');
            },
            fnCleanForm: function () {
                this.entity = {
                    entityId: undefined,
                    projectId: undefined,
                    workDate: undefined,
                    workHours: undefined,
                    user: undefined
                };
                this.$refs.frmWork.reset();
                this.v$.$reset();
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
          },
          fnGetMinDate() {
            var result = new Date(2024, 0, 1);
            if (arrayHasValue(this.asset["maintenanceLog"]))
            {
              // Turn your strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
              let sortedArray = this.asset.maintenanceLog
                .toSorted(function (a, b) { return new Date(b.timestamp) - new Date(a.timestamp); })
                .reverse();

              result = sortedArray[0].timestamp;
            }

            return result;
          },
        }
    };
</script>
<style scoped>

</style>

