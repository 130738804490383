<template>
  <div class="flex m-auto lg:w-[50rem] w-[38rem]">
    <h2 class="text-4xl leading-tight text-left text-bold m-auto">{{ assetType }} Details</h2>
    <div class="ml-auto">
      <Button v-if="!asset.disposed" label="Dispose" title="Dispose"
              class="max-h-10 w-28 mt-2" severity="warning"
              @click="fnDisposeOpen" rounded size="small" />
      <Tag v-if="asset.disposed" class="mt-4 max-h-10" value="Disposed" severity="danger" />
    </div>
  </div>
  <asset-details :assetId="entityId"
                 :isOpen="isOpen"
                 :dispose="disposed"
                 @onClose="fnCancel"
                 @onWorkAdded="fnWorkAdded"
                 @onWorkRemoved="fnWorkRemoved"
                 @onSave="fnSave"
                 @setAsset="fnSetAsset"
                 @onError="fnShowToast"></asset-details>
  <!--@onDispose="fnDispose"-->
  <loader-component :isLoading="isLoading" type="primary"></loader-component>
  <asset-dispose-component :assetId="entityId" :assetName="asset.name" :isOpen="openDisposeWindow"
                           @onError="fnShowToast" @onDispose="fnDisposeAsset" @onClose="fnDisposeClose" />
</template>
<script>
  import LoaderComponent from "@/components/LoaderComponent.vue";
  import AssetDetails from "@/components/Assets/AssetDetailsComponent.vue";
  import AssetDisposeComponent from '@/components/Assets/AssetDisposeComponent'

  import Tag from "primevue/tag";
  import Button from "primevue/button";

  import { ToastSeverity } from 'primevue/api';
  import Constants from "@/utils/Constants.js";

  export default {
      name: "AssetDetailsPage",
      components: {
        AssetDetails, Tag, Button,
        LoaderComponent,
        AssetDisposeComponent,
      },
      data() {
          return {
              isLoading: false,
              entityId: undefined,
            isOpen: false,
            assetType: "Asset",
            asset: {
              name: "",
              disposed: false,
            },
            openDisposeWindow: false,
            disposed: {}
          };
      },
      created() {
          console.log("Asset Details Page: Created");

          this.entityId = this.$route.params.entityId;
          console.log("AssetId: " + this.$route.params.entityId);

          this.$store.dispatch("parseHash").
              then(() => {
                  this.fnLoad();
              });
      },
      mounted() {
          this.$logService.save(this.$options.name);
      },
      unmounted() {
          console.log("Unmounting Asset Details Page...");
      },
      methods: {
          fnLoad: function () {
              console.log("Asset  Details: loading - " + this.entityId);
              this.isLoading = true;
              this.isOpen = true;
              this.isLoading = false;
          },
          fnWorkAdded(assetName) {
              //this.fnShowToast('Save Success', 'The Asset "' + entitySaved.batch + '" was inserted!', ToastSeverity.SUCCESS);
              this.fnShowToast('Add Work Success', `The working hours have been added to the ${this.assetType} "${assetName}".`, ToastSeverity.SUCCESS);
          },
          fnDispose(assetName) {
              //this.fnShowToast('Save Success', 'The Asset "' + entityNew.batch + '" was updated!', ToastSeverity.SUCCESS);
            this.fnShowToast('Dispose Success', `The ${this.assetType} "${assetName}" was disposed!`, ToastSeverity.WARN);
          },
          fnSave(entity) {
              //this.fnShowToast('Save Success', 'The Asset "' + entityNew.batch + '" was updated!', ToastSeverity.SUCCESS);
            this.fnShowToast('Save Success', `The ${this.assetType} "${entity.name}" was updated successfully!`, ToastSeverity.SUCCESS);
          },
          fnWorkRemoved(entityName) {
              //this.fnShowToast('Delete Success', `The ${this.assetType} "${entityName}" was deleted!`, ToastSeverity.WARN);
            this.fnShowToast('Remove Work Success', `The working hours have been removed from the ${this.assetType} '${entityName}'.`, ToastSeverity.WARN);
          },
          fnShowToast(title, message, severity) {
              this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
          },
          fnShowMessage(title, message, severity) {
              this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
          },
          fnCancel(assetType) {
              //console.log(assetType);
              //alert(assetType);
              if (assetType == Constants.Assets.TypeCode.ROBOT) { 
                  this.$router.push({ name: "robots" });
              }
              else
              {
                  this.$router.push({ name: "asset-management" });                    
              }
          },
          fnSetAsset(asset) {
            this.asset = asset;
            if (asset.assetType == Constants.Assets.TypeCode.ROBOT) {
              this.assetType = "Robot";
              //this.$emit("setAssetType", "Robot");
            }
          },
          fnDisposeOpen() {
            this.openDisposeWindow = true;
          },
          fnDisposeClose() {
            this.openDisposeWindow = false;
          },
          fnDisposeAsset(dispose) {
            //console.log(dispose);
            this.fnShowToast('Dispose Success', 'The asset "' + this.asset.name + '" was disposed!', ToastSeverity.WARN);
              
            this.asset.disposed = true;
            this.asset.dispose = dispose;

            this.$emit("onDispose", this.entity.name);
            this.disposed = dispose;

            this.fnDisposeClose();  
          },
      }
    
  };

</script>
