import axios from "axios";
import store from "../security/store.js";

const _axiosObject = axios.create();
class AxiosService {
  constructor(baseApi) {
    this.apiBase = baseApi;

    _axiosObject.interceptors.request.use((request) => {
      if (store.getters.isAuthenticated) {
        const access_token = store.getters.accessToken;
        request.headers.Authorization = `${access_token}`;
      }
      return request;
    });
  }

  setBaseApi(baseApi) {
    this.apiBase = baseApi;
  }

  doPost(path, entity) {
    return new Promise((resolve, reject) => {
      _axiosObject
        .post(`${this.apiBase}/${path}`, entity)
        .then((response) => {
          if (response != undefined && response.status == 200) {
            resolve(response.data);
          } else {
            reject(this.handleError(response));
          }
        })
        .catch((error) => {
          delete error["stack"];
          reject(this.handleError(error));
        });
    });
  }

  doPut(path, entity) {
    return new Promise((resolve, reject) => {
      _axiosObject
        .put(`${this.apiBase}/${path}`, entity)
        .then((response) => {
          if (response != undefined && response.status == 200) {
            resolve(response.data);
          } else {
            reject(this.handleError(response));
          }
        })
        .catch((error) => {
          delete error["stack"];
          reject(this.handleError(error));
        });
    });
  }

  doGet(path) {
    return new Promise((resolve, reject) => {
      _axiosObject
        .get(`${this.apiBase}/${path}`)
        .then((response) => {
          if (response != undefined && response.status == 200) {
            resolve(response.data);
          } else {
            reject(this.handleError(response));
          }
        })
        .catch((error) => {
          delete error["stack"];
          reject(this.handleError(error));
        });
    });
  }

  doDelete(path) {
    return new Promise((resolve, reject) => {
      _axiosObject
        .delete(`${this.apiBase}/${path}`)
        .then((response) => {
          if (response != undefined && response.status == 200) {
            resolve(response.data);
          } else {
            reject(this.handleError(response));
          }
        })
        .catch((error) => {
          delete error["stack"];
          reject(this.handleError(error));
        });
    });
  }

  handleError(error) {
    //if (error.response !== undefined && error.response.status == 422) {
    if (error.response !== undefined) {
      return {
        code: error.code,
        status: error.response.status,
        statusText: error.response.statusText,
        message: error.message,
        details: error.response.data,
      };
    }
    return error;
  }
}

export const axiosService = new AxiosService("");
