<template>
  <div class="card p-4 bg-gray-800 text-white rounded-lg">
    <h1 v-if="dataType === 'incidents'" class="flex justify-center pt-3 pb-10 text-[28px] font-bold">Total Incidents by Date Range</h1>
    <h1 v-else class="flex justify-center pt-3 pb-10 text-[28px] font-bold">Total Concrete by Date Range</h1>
    <div class="flex flex-wrap justify-between gap-4 relative">
      <div class="date-range-selector w-full md:w-1/2">
      <div class="flex items-center space-x-4">
        <Button @click="toggleCalendar" class=" hover:underline">Filter by Date Range</Button>
        <span class="cursor-pointer text-xl mb-2">
          {{ selectedDateRangeLabel }}
        </span>
      </div>
      <div v-if="calendarVisible" class="absolute top-full left-0 z-50">
        <Calendar
          v-model="selectedDateRange"
          selectionMode="range"
          monthNavigator
          yearNavigator
          yearRange="2020:2030"
          dateFormat="mm/dd/yy"
          :inline="true"
          class="border border-gray-600 rounded-lg p-2 bg-gray-700"
        />
      </div>
      </div>
      <div class="date-range-selector w-full md:w-1/2">
        <div class="flex items-center space-x-4">
        <Button @click="toggleMonthCalendar" class=" hover:underline">Filter by Month</Button>        
        <span  class="block cursor-pointer text-xl mb-2">
          {{ selectedMonthLabel }}
        </span>
      </div>
      <div v-if="monthCaldarVisible" class="absolute top-full z-50">
        <Calendar          
          v-model="selectedMonth"
          yearNavigator
          yearRange="2020:2030"
          view="month"
          dateFormat="mm/yy"
          :inline="true"
          class="border border-gray-600 rounded-lg p-2 bg-gray-700"
        />
      </div>
    </div>
    <div>
      <Button @click="removeChartFilters" class=" hover:underline">Remove Filters</Button>
    </div>
    </div>

    <Chart
      type="bar"
      :data="chartData"
      :options="chartOptions"
      class="mt-6"
      style="height: 500px"
    />
  </div>
</template>


<script>
import Chart from "primevue/chart";
import Calendar from "primevue/calendar";
import axios from "axios";
import Button from 'primevue/button';
const textColor = "#f4f6f7";
const textColorSecondary = "#B2BEB5";

export default {
  components: {
    Chart,
    Calendar,
    Button
  },
  props: {
    chartObject: Object,
    url: String,
  },
  watch: {
    chartObject: {
      immediate: true,
      handler(newValue) {
        this.chart = newValue;
        this.getData();
      },
    },
    selectedMonth: function() { 
      if(this.selectedMonth)
      {           
      this.monthCaldarVisible = !this.monthCaldarVisible;
      this.selectedDateRange = null
      const formatDateToCustomString = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };
 
    const rawMonth = new Date(this.selectedMonth);
    this.month = formatDateToCustomString(rawMonth);    
    this.getDataMonth(); 
  }    
    },
    selectedDateRange: function(){
      if(this.selectedDateRange[1])      
    {                        
      this.calendarVisible = !this.calendarVisible;
      this.selectedMonth = null
      const formatDateToCustomString = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const rawStartDate = new Date(this.selectedDateRange[0]);
    const rawEndDate = new Date(this.selectedDateRange[1])
    this.startDate = formatDateToCustomString(rawStartDate);
    this.endDate = formatDateToCustomString(rawEndDate)
    this.getDataRange();
    }
      
    }
  },
  data() {
    return {
      dataType:"",
      selectedDateRange: null,
      selectedMonth: null,
      startDate: null,
      endDate:null,
      month:null,
      calendarVisible: false,
      monthCaldarVisible: false,
      chartData: {},
      chartOptions: {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: textColorSecondary,
            },
          },
        },
      },
    };
  },
  computed: {
    selectedDateRangeLabel() {
      if (this.selectedDateRange && this.selectedDateRange.length === 2) {
        return `${new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric" }).format(this.selectedDateRange[0])} - ${new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric" }).format(this.selectedDateRange[1])}`;
      }
      return "";
    },
    selectedMonthLabel() {
      if (this.selectedMonth) {
        return `${new Intl.DateTimeFormat("en-US", { month: "long", year: "numeric" }).format(this.selectedMonth)}`;
      }
      return "";
    },
  },
  methods: {    
    toggleCalendar() {            
      this.calendarVisible = !this.calendarVisible;
    },    
    toggleMonthCalendar() {                        
      this.monthCaldarVisible = !this.monthCaldarVisible;
    },  
    removeChartFilters() {
      this.selectedDateRange = null
      this.selectedMonth = null
      this.getData(); 
    },
    getData() {
      axios
        .get(`${this.$apiBase}/${this.url}`)
        .then((response) => {            
          this.dataType = response.data.dataType                                                       
          this.chartData = {
            labels: response.data.labels,
            datasets: [
              {
                label: response.data.conf.label,
                data: response.data.data,
                fill: false,
                tension: 0.4,
                pointRadius: 0.5,
                borderWidth: 0.8,
              },
            ],
          };
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    getDataRange() {
      axios
        .get(`${this.$apiBase}/${this.url}/${this.startDate}/${this.endDate}`)
        .then((response) => {                                        
          this.chartData = {
            labels: response.data.labels,
            datasets: [
              {
                label: response.data.conf.label,
                data: response.data.data,
                fill: false,
                tension: 0.4,
                pointRadius: 0.5,
                borderWidth: 0.8,
              },
            ],
          };
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
    getDataMonth() {
      axios
        .get(`${this.$apiBase}/${this.url}/${this.month}`)
        .then((response) => {                                        
          this.chartData = {
            labels: response.data.labels,
            datasets: [
              {
                label: response.data.conf.label,
                data: response.data.data,
                fill: false,
                tension: 0.4,
                pointRadius: 0.5,
                borderWidth: 0.8,
              },
            ],
          };
          
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    },
  },
  
};
</script>

<style>
.filter-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.filter-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #f4f6f7;
}
.date-range-selector {
  flex: 1;
}
.calendar-inline {
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 10px;
  background-color: #2c2f33; 
}
.date-range-label {
  cursor: pointer;
  font-size: 18px;
  display: block;
  color: #f4f6f7;
  margin-bottom: 10px;
}
</style>
