<template>
    <form id="frmDispose" name="frm-dispose" ref="frmDispose">
      <PrimeDialog v-model:visible="visible" modal :header="textHeader" @hide="fnCancel"
                   :draggable="false" :style="{ width: '50rem' }"
                   :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div class="mb-2">
          <div v-if="v$.entity.timestamp.$error" class="text-red-600 text-sm mb-2">Please enter a maintenance date.</div>
        <div>
          <span class="inline-block mr-2 w-40">Maintenance date:</span>
          <Calendar v-model="maintenanceDate" showIcon iconDisplay="input" placeholder="Working day" inputClass="w-48" 
                    :minDate="minMaintDate" :maxDate="maxMaintDate"/>
          </div>
        </div>
        <div class="mb-2">
          <div v-if="v$.entity.operationalHours.$error" class="text-red-600 text-sm mb-2">Please enter a operational hours.</div>
          <div>
            <span class="inline-block mr-2 w-40">Operational hours:</span>
            <InputNumber v-model="entity.operationalHours" placeholder="Operational hours" inputClass="w-48" />
          </div>
        </div>
        <div>
          <div v-if="v$.entity.notes.$error" class="text-red-600 text-sm">Please enter a maintenance notes.</div>
          <div>
            <!--<span class="block mr-2 w-40">Maintenance Notes:</span>
  <Textarea v-model="entity.notes" rows="6" class="w-full md:w-14rem" placeholder="Maintenance notes" />-->
            <Textarea v-model="entity.notes" rows="6" class="w-full" placeholder="Maintenance notes" />
          </div>
        </div>
        <template #footer>
          <Button label="Cancel" icon="pi pi-arrow-circle-left" iconPos="left"
                  class="mr-2 bg-surface-400 border-surface-400 hover:bg-surface-500"
                  title="Cancel and go back" @click="fnCancel"></Button>
          <Button label="Add" icon="pi pi-plus-circle" iconPos="right"
                  title="Add Maintanance" @click="fnAdd"></Button>
        </template>
      </PrimeDialog>
    </form>
</template>

<script>
    //import Textarea from 'primevue/textarea';
    import Calendar from 'primevue/calendar';
    import Button from 'primevue/button';
    import PrimeDialog from "primevue/dialog";
  import InputNumber from 'primevue/inputnumber';
  import Textarea from 'primevue/textarea';
  

    import { ToastSeverity } from 'primevue/api';
    import { useVuelidate } from '@vuelidate/core';
    // import { required} from '@vuelidate/validators'
  import { required, integer, minValue } from '@vuelidate/validators';

  import { assetService } from "@/services/AssetService.js";
  import { capitalizeFirstLetter, dateTimeToString, arrayHasValue} from "@/utils/utils.js";
  //import Constants from "@/utils/Constants.js";
//  import { addMonths } from 'date-fns';

    export default {
        name: "AssetMaintenanceComponent",
        emits: ["onError", "onClose", "onAdd"],
        setup() {
            return { v$: useVuelidate() }
        },
        components: {
            Textarea,
            InputNumber,
            Calendar, Button,
            PrimeDialog
        },
        props: {
          asset: Object,
          isOpen: Boolean
        },
        data() {
            return {
              entity: {
                assetId: undefined,
                projectId: undefined,
                timestamp: undefined,
                notes: undefined,
                operationalHours: undefined, 
                createdBy: undefined
              },
              maintenanceDate: undefined,
              visible: false,
              textHeader: "",
              minMaintDate: new Date(2024, 0, 1),
              maxMaintDate: new Date(),
            }
        },
        validations() {
            return {
              entity: {
                timestamp: { required },
                operationalHours: { required, minValue: minValue(1), integer },
                notes: { required }
              }
            }
        },
        watch: {
          isOpen: function (val) {
              if (val == true) {                    
                console.log("Asset Maintenance Component Opened!");
                this.textHeader = "Maintenance Asset: " + this.asset.name;
                this.entity.assetId = this.asset.assetId;                
                this.entity.operationalHours = this.asset.operationalTime;
                
                this.maintenanceDate = new Date();
                this.minMaintDate = this.fnGetMinDate();
                this.maxMaintDate= new Date();
              }
              this.visible = val;
          },
          maintenanceDate: function (val) {
            this.entity.timestamp = dateTimeToString(val);
          },
        },
        methods: {
          fnAdd() {
              this.v$.$validate()
                  .then(result => {
                    //console.log(JSON.stringify(this.entity));
                    if (result) {
                      this.loading = true;
                      this.entity.createdBy = this.$store.getters.username;
                      this.entity.projectId = this.$store.getters.project;

                      assetService.addMaintenance(this.entity)
                      .then((data) => {
                          //console.log(data);
                          this.entity.maintenanceId = data.upsertedId;
                          
                          this.$emit('onAdd', this.asset, this.asset.name);

                          this.loading = false;

                          this.fnCleanForm();
                          this.$emit('onClose');
                        })
                        .catch((error) => {
                          console.log(error);
                          this.$emit('onError', 'Unexpected Error.', 'An error occurred when trying to dispose the Asset: "' + this.asset.name + '"!!!', ToastSeverity.ERROR);
                          this.loading = false;
                        });

                      this.loading = false;

                      return true;
                    }
                    else {
                      console.log(this.v$.alertObj.name);
                    }
                  })
                  .catch(error => {
                      console.log(error);
                  });
                
              return false;
            },
            fnCancel() {
              this.fnCleanForm();
              this.visible = false;
              this.$emit('onClose');
            },
            fnCleanForm: function () {
              this.entity = {
                assetId: undefined,
                projectId: undefined,
                timestamp: undefined,
                notes: undefined,
                operationalHours: undefined,
                createdBy: undefined
              };
              this.maintenanceDate = undefined;
              this.textHeader = undefined;

              this.$refs.frmDispose.reset();
              this.v$.$reset();
            },
            fnGetMinDate(){
              var result = new Date(2024, 0, 1);
              if (arrayHasValue(this.asset["maintenanceLog"]))
              {
                // Turn your strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
                let sortedArray = this.asset.maintenanceLog
                  .toSorted(function(a,b){ return new Date(b.timestamp) - new Date(a.timestamp); })
                  .reverse();

                result = sortedArray[0].timestamp;
              }

              return result;
            },
            fnOpenConfirmBox: function () {
                this.confirmObj.message = "Do you really want to delete the alert: '" + this.alertObj.name + "'?";
                this.confirmObj.isOpen = true;
            },
            fnCloseConfirmBox: function () {
                this.confirmObj.message = "";
                this.confirmObj.isOpen = false;
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
        }
    };
</script>
