<template>
  <h2 class="text-4xl leading-tight text-center">Project Alerts</h2>
  <div class="px-5 flex-auto">
    <dynamic-widget-component tab="project-alerts"></dynamic-widget-component>
  </div>
</template>

<script>
import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";
export default {
        name: "ProjectAlertsPage",
  components: {
    DynamicWidgetComponent,
    },
    created() {
      console.log("ProjectAlertsPage: Created");
      this.$store.dispatch("parseHash");
    },
    mounted() {
        this.$logService.save(this.$options.name);
    },
};
</script>
