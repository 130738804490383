<template>
    <div class="flex align-middle px-5">
        <h2 class="text-4xl leading-tight text-center m-auto text-bold">Ingestion Details</h2>
        <!--<Button type="button" label="ALERT" icon="pi pi-plus-circle" iconPos="right"
                class="ml-auto" title="Alert" @click="fnAddNew"></Button>-->
    </div>
    <IngestionComponent :ingestId="ingestId"
                        :isOpen="isOpen"
                        @onCancel="fnCancel"
                        @onSave="fnOnSave"
                        @onAdd="fnOnAdd"
                        @onDelete="fnOnDelete"
                        @onError="fnShowToast"></IngestionComponent>

    <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
</template>
<script>
    import LoaderComponent from "@/components/LoaderComponent.vue";
    import IngestionComponent from "@/components/IngestionComponent.vue";
    
    import { ToastSeverity } from 'primevue/api';
    
    export default {
        name: "AlertDetailsPage",
        components: {            
            IngestionComponent,
            LoaderComponent
        },
        data() {
            return {
                isLoading: false,
                ingestId: undefined, 
                isOpen: false
            };
        },
        created() {
            console.log("Ingestion Details: Created");
            console.log(this.$route.params);
            
            this.ingestId = this.$route.params.ingestId;
            console.log("alertId: " + this.$route.params.ingestId);

            this.$store.dispatch("parseHash").
                then(() => {
                    this.fnLoad();
                });
        },
        unmounted() {
            console.log("Unmounting Alert Manager Page...");
        },
        mounted() {
            this.$logService.save(this.$options.name);
        },
        methods: {
            fnLoad: function () {
                console.log("Ingestion Details: loading - " + this.ingestId);
                this.isLoading = true;
                this.isOpen = true;
                this.isLoading = false;
            },
            fnOnAdd(alertSaved) {
                this.fnShowToast('Save Success', 'The alert "' + alertSaved.name + '" was inserted!', ToastSeverity.SUCCESS);
            },
            fnOnSave(alertNew) {
                this.fnShowToast('Save Success', 'The alert "' + alertNew.name + '" was updated!', ToastSeverity.SUCCESS);
            },
            fnOnDelete(alertName) {

                this.fnShowToast('Delete Success', 'The alert "' + alertName + '" was deleted!', ToastSeverity.WARN);
                //console.log('warning - The alert "' + alertName + '" was deleted!');
                this.fnCancel();
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCancel() {
                this.$router.push({ name: "ingestion-manager" });
            }
        }
    };

</script>
