<template>
    <h2 class="text-4xl leading-tight text-center m-auto">Project List</h2>
    <div class="h-fit flex flex-wrap" v-if="!isLoading">
        <div v-for="(project, index) in projectsArray"
                :key="index"
                class="my-1 px-1 w-full md:w-1/2 md:pr-4 lg:pr-0 sm:my-4 lg:my-4 lg:px-4 lg:w-1/4 sm:w-[90%] sm:mx-auto md:mx-0" 
                >
            <!-- Article -->
            <article class="overflow-hidden rounded-lg shadow-lg hover:bg-indigo-100 dark:bg-indigo-300">
                <a @click="fnSelect(project.id, project.name)" href="#">
                    <img alt="Placeholder" class="block h-[300px] w-[600px]"
                            :src="getImageSource(project.imageBase64)" />
                </a>
                <header class="flex items-center justify-between leading-tight p-2 md:p-4">
                    <h1 class="text-lg">
                        <a class="no-underline hover:underline text-black" @click="fnSelect(project.id, project.name)" href="#">
                            {{ project.name }}
                        </a>
                    </h1>
                </header>
            </article>
            <!-- END Article -->
        </div>
        <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 p-4 sm:mx-auto lg:mx-0">
            <div class="bg-gray-200 p-6 rounded-lg shadow-md">
                <div class="flex items-center justify-center h-full">
                    <!--
                        <input type="button" @click="redirectToNewProject" value="Create New Project"
                                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" />
                    -->
                    <Button type="button" label="Create New Project" icon="pi pi-plus-circle" iconPos="right"
                            title="Create " @click="redirectToNewProject"></Button>
                </div>
            </div>
        </div>
    </div>
    <LoaderComponent :isLoading="isLoading" type="primary"></LoaderComponent>
</template>
<script>
import LoaderComponent from "@/components/LoaderComponent";
import { ToastSeverity } from 'primevue/api';

import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { catalogService } from "@/services/CatalogService.js";
import { userService } from "@/services/UserService.js";

import Button from 'primevue/button';

export default {
  name: "SelectProjectPage",
  setup() {
    return { v$: useVuelidate() };
  },
  emits: ["status"],
  components: {
      LoaderComponent,
      Button
  },
  computed: {},
  data() {
    return {
      isLoading: false,
      username: undefined,
      project: "",
        projectsArray: []
    };
  },
  validations() {
    return {
      project: { required },
    };
  },
  created() {
    console.log("SelectProject: Created");
    this.username = this.$store.getters.username;
  },
  mounted() {
    this.fnLoadProjects();
      //this.$logService.save("SelectProjectPage");
      this.$logService.save(this.$options.name);
  },
  methods: {
    getImageSource(base64Image) {
      if (base64Image === "" || base64Image == null) {
        return "https://placehold.co/600x400";
      } else {
        return base64Image;
      }
    },
    redirectToNewProject() {
      this.$router.push({ name: "create-project" }); // Replace 'NewProject' with the name of your route
    },
    fnGetRoute() {
      /*let roles = this.$store.getters.roles;
      let route = "";
      if (Array.isArray(roles) && roles.length > 0) {
        if (roles.includes("admin")) {
          //route = "/dss/page/supervisor";
          route = "supervisor";
        } else {
          //route = "/dss/page/" + roles[0];
          route = roles[0];
        }
      } else {
        //route = "/dss/page/manage-account";
        route = "manage-account";
      }
        return route;*/
        return "project-info";
    },
    fnSelect(id, name) {
      //Update account HERE
      this.$store.commit("SET_PROJECT", id);
      let route = this.fnGetRoute();
      this.isLoading = true;

      userService
        .setProject(this.username, id)
        //.then((data) => {
        //console.log(data);
          .then(() => {
          console.log("changeProject - ID: ", id, " - Name: ", name);
          this.$emitter.emit("changeProject", {"id": id, "name":name});
          
          this.isLoading = false;
          //console.log("route - " + route);
          this.$emitter.emit("navigatePage", route);
        })
        .catch((error) => {
          console.log(error);
          this.fnShowToast("Error", "An error occurred when trying to select the project!", ToastSeverity.ERROR);
          this.isLoading = false;
        });
    },
    fnShowToast(title, message, severity) {
      this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
    },
    fnShowMessage(title, message, severity) {
        this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
    },
    fnLoadProjects() {
        this.projectsArray = [];
        this.isLoading = true;

        catalogService.projects()
        .then((data) => {
          
          if (data != undefined && Array.isArray(data)) {
            this.projectsArray = data;
          }
            this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
            this.isLoading = false;          
          this.fnShowToast("Error", "Error when loading the projects list...!!!", ToastSeverity.ERROR);
        });
    },
  },
};
</script>
