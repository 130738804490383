<template>
  <div v-if="loading" class="lg:w-[50rem] w-[38rem] mb-2 mx-auto">
    <div class="w-full border-2 p-2 shadow-md table-widget rounded-md border-indigo-400 my-2 flex mx-auto">
      <loader-mini-component></loader-mini-component>
    </div>
  </div>
    <form id="frmSave" name="frm-save" ref="frmSave">
        <div v-if="!loading">
            <div class="pt-2 flex-auto text-left lg:w-[50rem] w-[38rem] mx-auto">
                <div class="w-full border-2 p-2 shadow-md table-widget rounded-md border-indigo-400 my-2">
                  <div class="w-full flex lg:flex-row flex-col">
                    <div class="m-0">
                      <div class="w-full flex lg:flex-row flex-col mb-2">
                        <div class="w-32 text-indigo-800 dark:text-indigo-300 pt-2">ID:</div>
                        <div class="w-64 mr-4 pt-2">{{ entity.assetId }}</div>
                        <div class="w-32 text-indigo-800 dark:text-indigo-300 pt-2">Company Id:</div>
                        <div class="w-64">
                          <div v-if="v$.entity.companyId.$error" class="text-red-600 text-sm">Please enter the Company Id for the {{ typeName }}.</div>
                          <InputText type="text" v-model="entity.companyId" class="w-full" placeholder="Company Identifier"
                                     size="small" :invalid="v$.entity.companyId.$error" />
                        </div>
                      </div>
                      <div class="w-full flex lg:flex-row flex-col mb-2">
                        <div class="w-32 text-indigo-800 dark:text-indigo-300 pt-2">{{ typeName }} Name:</div>
                        <div class="w-64 mr-4">
                          <div v-if="v$.entity.name.$error" class="text-red-600 text-sm">Please enter a Name for the {{ typeName }}.</div>
                          <InputText type="text" v-model="entity.name" class="w-full" placeholder="Name"
                                     size="small" :invalid="v$.entity.name.$error" />
                        </div>
                        <div class="w-32 text-indigo-800 dark:text-indigo-300 pt-2">{{ typeName }} Type:</div>
                        <div v-if="isRobot" class="w-64 pt-2">
                          {{ fnCapText(entity.assetType) }}
                        </div>
                        <div v-else class="w-64">
                          <span v-if="v$.entity.assetType.$error" class="text-red-600 text-sm">Please select the {{ typeName }} Type.</span>
                          <Dropdown v-model="entity.assetType" :options="assetTypes" optionLabel="text" optionValue="code" placeholder="Please Select a Type"
                                    class="w-full" :invalid="v$.entity.assetType.$error" />
                        </div>
                      </div>
                      <div class="w-full flex lg:flex-row flex-col">
                        <div class="w-32 text-indigo-800 dark:text-indigo-300">Notes:</div>
                        <div class="lg:w-[41rem] w-full">
                          <Textarea v-model="entity.notes" class="w-full" rows="5" />
                        </div>
                      </div>
                    </div>
                  </div>
                    <table class="table-auto lg:w-[49rem] w-full mt-2 text-center">
                        <thead>
                            <tr>
                                <th class="font-bold  bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100" 
                                    colspan="5">
                                    Hours
                                </th>
                                <th class="font-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100" 
                                    rowspan="2">
                                    Failure <br />Probabilty
                                </th>
                            </tr>
                            <tr>
                              <th class="font-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">
                                Maximum
                              </th>
                              <th class="font-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">
                                Operational
                              </th>
                              <th class="font-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">
                                Remaining
                              </th>
                              <th class="font-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">
                                Predicted
                              </th>
                              <th class="font-bold bg-surface-200 text-indigo-600 border border-surface-400 p-1 dark:bg-primary-400/30 dark:text-indigo-100">
                                Total Operational
                              </th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="border border-surface-400 p-1 text-center">
                              <div v-if="v$.entity.lifetimeSpan.$error" class="text-red-600 text-sm mb-2">
                                lifetime > 0
                              </div>
                              <InputNumber v-model="entity.lifetimeSpan" placeholder="Lifetime" inputClass="lg:w-32 w-full" size="small" />
                            </td>
                            <td class="border border-surface-400 p-1 text-center">
                              <div v-if="v$.entity.operationalTime.$error" class="text-red-600 text-sm mb-2">
                                operational time >= 0
                              </div>
                              <InputNumber v-model="entity.operationalTime" placeholder="Operational time" inputClass="lg:w-32 w-full" size="small" />
                            </td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.lifetimeLeft) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.lifetimeEstimated) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatNumber(entity.totalOperational) }}</td>
                            <td class="border border-surface-400 p-1 text-center">{{ fnFormatPercentage(entity.failureProbability) }}</td>
                          </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="lg:w-[50rem] w-[38rem] mb-2 mx-auto" v-if="entity.disposed">
                <div class="w-full border-2 p-2 shadow-md table-widget rounded-md border-indigo-400 my-2">
                    <div class="w-full flex lg:flex-row flex-col">
                        <div class="w-32 text-indigo-800">Disposed on:</div>
                        <div class="lg:w-64 w-fit ">{{ fnFormatDate(entity.dispose.disposeDate) }}</div>
                        <div class="w-32 text-indigo-800">Disposed type:</div>
                        <div class="lg:w-64 w-fit ">{{ fnCapText(entity.dispose.disposeType) }}</div>
                    </div>
                    <div class="w-full flex flex-row">
                        <div class="w-32 text-indigo-800">Reason:</div>
                        <div>{{ fnCapText(entity.dispose.reason) }}</div>
                    </div>
                </div>
            </div>
            <div class="lg:w-[50rem] w-[38rem] mb-2 mx-auto">
              <div class="w-full border-2 p-2 shadow-md table-widget rounded-md border-indigo-400 my-2 flex">
                <div class="w-full flex-row">
                  <div class="flex align-middle mb-2">
                    <h3 class="text-indigo-600 text-center m-auto font-bold"
                        style="text-transform: uppercase;">
                      Maintenance performed
                    </h3>
                    <Button v-if="!entity.disposed" icon="pi pi-plus" title="Add maintenance" class="ml-auto"
                            @click="fnMaintOpen" rounded size="small" />
                  </div>
                  <DataTable :value="entity.maintenanceLog" size="small" scrollHeight="16.5rem" sortField="timestamp" :sortOrder="-1"
                             paginator :rows="8" :rowsPerPageOptions="[8, 12, 24, 48]">
                    <Column field="timestamp" header="Date" sortable>
                      <template #body="{ data }">
                        {{ fnFormatTextDate(data.timestamp) }}
                      </template>
                    </Column>
                    <Column field="operationalHours" header="Hours worked" sortable></Column>
                    <Column field="notes" header="Notes" sortable></Column>
                    <Column :exportable="false" class="w-16">
                      <template #body="{ data }">
                        <div class="w-12">
                          <Button v-if="!entity.disposed" icon="pi pi-trash" outlined rounded severity="danger" size="small" title="Delete"
                                  class="dark:text-red-300 dark:border-red-300"
                                  @click="fnDeleteMaint($event, data)" />
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
            <div class="lg:w-[50rem] w-[38rem] mb-2 mx-auto">
                <div class="w-full border-2 p-2 shadow-md table-widget rounded-md border-indigo-400 my-2 flex">
                    <div class="w-1/2 flex-row">
                        <div class="flex align-middle mb-2">
                            <h3 class="text-indigo-600 text-center m-auto font-bold"
                                style="text-transform: uppercase;">
                                Worked hours
                            </h3>
                            <Button v-if="!entity.disposed" icon="pi pi-plus" title="Add worked hours" class="ml-auto"
                                    @click="fnWorkedTimeOpen" rounded size="small" />
                        </div>
                        <DataTable :value="entity.workLog" size="small" scrollHeight="16.5rem" sortField="workDate" :sortOrder="-1"
                                   paginator :rows="8" :rowsPerPageOptions="[8, 12, 24, 48]">
                            <Column field="workDate" header="Date" sortable>
                                <template #body="{ data }">
                                    {{ fnFormatTextDate(data.workDate) }}
                                </template>
                            </Column>
                            <Column field="workHours" header="Worked hours" sortable></Column>
                            <Column :exportable="false" class="w-16">
                                <template #body="{ data }">
                                    <div class="w-12">
                                        <Button v-if="!entity.disposed && data.workDate > fnGetMinDate()" icon="pi pi-trash" outlined rounded severity="danger" size="small" title="Delete"
                                                class="dark:text-red-300 dark:border-red-300"
                                                @click="fnDeleteWork($event, data)" />
                                    </div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div class="w-1/2 flex-row">
                        <img width="600" :src="imageUrl" alt="End lifetime Forecast..." />
                    </div>
                </div>
            </div>
        </div>
        <div class="pt-2 lg:w-[50rem] w-[38rem] mx-auto mt-2 flex flex-wrap">
            <div class="ml-auto">
                <Button label="Back" title="Back to management page" severity="secondary"
                        @click="fnClose" icon="pi pi-arrow-circle-left" iconPos="left"></Button>
                <Button v-if="!entity.disposed" label="Save" title="Save the information." class="ml-2"
                        @click="fnSave" icon="pi pi-plus-circle" iconPos="right" />
            </div>
        </div>
    </form>
    
    <asset-maintenance-component :asset="entity" :isOpen="openMaintWindow"
                                 @onError="fnShowToast" @onAdd="fnMaintenanceAdd" @onClose="fnMaintClose" />
    <asset-work-component :asset="entity" :isOpen="openWorkWindow"
                          @onError="fnShowToast" @onAddWork="fnWorkedTimeAdd" @onClose="fnWorkedTimeClose" />
</template>

<script>
    import Button from 'primevue/button';
    import DataTable from 'primevue/datatable';
    import Column from 'primevue/column';
    //import Tag from "primevue/tag";

    import Textarea from 'primevue/textarea';
    import InputText from 'primevue/inputtext';
    import Dropdown from 'primevue/dropdown';
    import InputNumber from 'primevue/inputnumber';

    import { ToastSeverity } from 'primevue/api';
    import { useConfirm } from "primevue/useconfirm";

    import { useVuelidate } from '@vuelidate/core'
    import { required, minValue, integer } from '@vuelidate/validators'

    import { stellioService } from "@/services/StellioService.js";
    import { assetService } from "@/services/AssetService.js";
    import {
        formatDate,
        formatNumber,
        formatPercentage,
        capitalizeFirstLetter,
        convertBase64ToImage,
        arrayHasValue
    } from "@/utils/utils.js";

    import Constants from "@/utils/Constants.js";

    import LoaderMiniComponent from '@/components/LoaderMiniComponent'
    import AssetMaintenanceComponent from '@/components/Assets/AssetMaintenanceComponent'
    import AssetWorkComponent from '@/components/Assets/AssetWorkComponent'

  export default {
    name: "AssetDetailsComponent",
    emits: ["onError", "onClose", /*"onDispose",*/ "onWorkAdded", "onWorkRemoved", "onSave", "setAsset"],
        setup() {
            return { v$: useVuelidate({ $scope: "details" }) }
        },
        components: {
            DataTable, Column, //Tag,
            LoaderMiniComponent,
            //AssetDisposeComponent,
            AssetMaintenanceComponent,
            AssetWorkComponent,
            Textarea, InputText, InputNumber,
            Button, Dropdown
        },
        props: {
            assetId: String,
            dispose: Object,
            isOpen: Boolean
        },
        data() {
            return {
                loading: true,
                editing: false,
                entity: {
                    assetId: undefined,
                    name: undefined,
                    companyId: undefined,
                    assetType: undefined,
                    disposed: false,
                    updatedBy: undefined,
                    notes: undefined,
                    lifetimeSpan: undefined,
                    operationalTime: undefined,
                    totalOperational: undefined,
                    dispose: {
                        reason: "",
                        disposeDate: undefined,
                        disposeType: undefined
                    },
                  workLog: [],
                  maintenanceLog: []
                },
                confirmObj: {
                    message: "",
                    isOpen: false
                },
                disposeStatus: [
                    { name: 'Failed', code: 'failed' },
                    { name: 'End of lifetime', code: 'lifetime-end' }
                ],
                disposeTypes: Constants.Assets.DISPOSE_TYPE,
                visible: false,
                //openDisposeWindow: false,
                openWorkWindow: false,
                openMaintWindow: false,
                failureProbability: undefined,
                imageUrl: undefined,
                assetTypes: Constants.Assets.TYPE,
                isRobot: false,
                typeName: "Asset"
            }
        },
        validations() {
            return {
                entity: {
                    name: { required },
                    companyId: { required },
                    assetType: { required },
                    lifetimeSpan: { required, minValue: minValue(1), integer },
                    operationalTime: { required, minValue: minValue(0), integer }
                }
            }
        },
        created() {
            console.log("Asset Details Component: Created");
            this.confirm = useConfirm();
        },
        watch: {
            isOpen: function (val) {
                if (val == true) {
                    console.log("Dispose Asset Component Opened!");
                    this.entity.assetId = this.assetId;
                    this.fnLoadAsset();
                    this.visible = this.isOpen;
                }
          },
          dispose: function (val) {
            this.entity.dispose = val;
            this.entity.disposed = true;
          },
        },
        methods: {
            fnLoadAsset() {
                this.loading = true;
                assetService.get(this.entity.assetId)
                    .then((data) => {
                      //console.log(data);
                      if (arrayHasValue(data.workLog)){
                        data.workLog.forEach((item) => { item.workDate = new Date(item.workDate); })
                        //data.workLog.sort((a, b) => Number(a.workDate ) - Number(b.workDate ));
                      }
                      if (arrayHasValue(data.maintenanceLog)){
                        data.maintenanceLog.forEach((item) => { item.timestamp = new Date(item.timestamp); })
                      }
                      this.entity = data;
                      this.isRobot = data.assetType == Constants.Assets.TypeCode.ROBOT;
                      if (this.isRobot) {
                          this.typeName = "Robot";
                          //this.$emit("setAssetType", "Robot");
                      }
                      this.$emit("setAsset", this.entity);
                      
                      this.fnLoadGraph();
                      this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                      this.$emit('onError', 'Unexpected Error',  `An error occurred when trying to load the ${ this.typeName }: '${ this.entity.name }'"!!!`, ToastSeverity.ERROR);
                        this.loading = false;
                    });
            },
            fnLoadGraph() {
                console.log("Open chart for Asset Type: ");
                //console.log(asset);
                const data = {
                    simulator: "Survival_analysis",
                    simulatorName: "Survival_analysis",
                    inputs: {},
                    params: {},
                    schedule: {
                        type: "click",
                    },
                };

                data["inputs"] = {
                    id: this.entity.companyId,
                    type: this.entity.type,
                    operationalTime: this.entity.operationalTime,
                    disposed: this.entity.disposed,
                    assetType: this.entity.assetType
                };

                stellioService.simulate(data)
                    .then((data) => {
                        //console.log(data);
                        if (data != undefined &&
                            data.failureProbability != undefined) {
                            this.failureProbability = data.failureProbability;
                            this.imageUrl = convertBase64ToImage(data.image);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$emit('onError', `An error occurred when trying to load the Graph for ${this.typeName}: '${this.entity.name}'!!!`, ToastSeverity.ERROR);
                    });
            },
            fnSave() {
                this.v$.$validate()
                    .then((result) => {
                        // console.log("fnsave", this.v$.$errors);
                        if (result) {
                            this.loading = true;
                            this.entity.projectId = this.$store.getters.project;
                            this.entity.updatedBy = this.$store.getters.username;

                            assetService.save(this.entity)
                                .then((data) => {
                                    console.log(data);
                                    //this.entity.assetId = data.upserted_id;
                                    this.$emit('onSave', this.entity);
                                    this.loading = false;
                                    this.fnLoadAsset();
                                })
                                .catch((error) => {
                                    console.log(error);
                                    this.$emit('onError', 'Unexpected Error', `An error occurred when trying to Add the ${this.typeName}!!!`, ToastSeverity.ERROR);
                                    this.loading = false;
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                
                return false;
            },
            fnClose() {
                let assetType = this.entity.assetType
                this.fnCleanForm();
                this.visible = false;
                this.$emit('onClose', assetType);
            },
            fnCleanForm: function () {
                this.entity = {
                    assetId: undefined,
                    disposed: false,
                    user: undefined,
                    notes: undefined,
                    dispose: {
                        reason: "",
                        disposeDate: undefined,
                        disposeType: undefined
                    },
                    workLog: []
                };
                this.$refs.frmSave.reset();
                this.v$.$reset();
            },
            fnMaintOpen(){
              this.openMaintWindow = true;
            },
            fnMaintClose() {
              this.openMaintWindow = false;
            },
            fnMaintenanceAdd() {
              //console.log(result);  
              this.fnShowToast('Maintenace Success', 'The maintenance record have been added to the asset "' + this.entity.name + '".', ToastSeverity.SUCCESS);
              // Reload the Asset. Wait for algorithm to reload the estimated values. 
              this.fnLoadAsset();
                
              // Close 
              this.fnMaintClose();
            },
            fnWorkedTimeOpen() {
                this.openWorkWindow = true;
            },
            fnWorkedTimeClose() {
                console.log("fnWorkedTimeClose.");
                this.openWorkWindow = false;
            },
            fnWorkedTimeAdd(result) {
                
                console.log("Add work to Asset.", result);
                this.$emit("onWorkAdded", this.entity.name);

                //Reload asset data to get the new Probability and lifetime estimated.
                this.fnLoadAsset()
                this.fnWorkedTimeClose();
                
            },
          fnDeleteMaint(event, item) {
            this.confirm.require({
              target: event.currentTarget,
              message: 'Do you want to delete this register?',
              icon: 'pi pi-info-circle',
              rejectClass: 'p-1 text-sm text-white dark:text-surface-900 bg-surface-500 dark:bg-surface-400 border border-surface-500 dark:border-surface-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300 focus:ring-surface-400/50 dark:focus:ring-surface-300/50',
              acceptClass: 'p-1 text-sm text-white dark:text-surface-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300 focus:ring-red-400/50 dark:focus:ring-red-300/50',
              rejectLabel: 'Cancel',
              acceptLabel: 'Delete',
              position: "topright",
              accept: () => {
                //this.loading = true;

                let maintenance = {
                  assetId: this.entity.assetId,
                  maintenanceId: item.maintenanceId,
                  operationalHours: item.operationalHours,
                  user: this.$store.getters.username
                };

                console.log("Delete item", maintenance);

                assetService.removeMaintenance(maintenance)
                  .then((data) => {
                    //this.fnShowToast('Remove Work Success', `The working hours have been removed from the ${this.typeName} '${this.assetName}'.`, ToastSeverity.WARN);
                    console.log("Maintenance removed from Asset.", data);

                    // Update component with values. 
                    this.entity.operationalTime = maintenance.operationalHours;

                    const index = this.entity.maintenanceLog.indexOf(item);
                    if (index > -1) { // only splice array when item is found
                      this.entity.maintenanceLog.splice(index, 1);
                    }
                    //this.$emit('onMaintenanceRemoved', this.entity.name);
                    this.fnShowToast("Maintenance removed.",  'The maintenance record have been removed from asset "' + this.entity.name + '".', ToastSeverity.WARN);
                    //this.loading = false;
                  })
                  .catch((error) => {
                    console.log(error);
                    this.$emit('onError', 'Unexpected Error', 'An error occurred when trying to work assigned!!!', ToastSeverity.ERROR);
                    //this.loading = false;
                  });
              },
              reject: () => {
                console.log("Cancel... Do nothing.");
              }
            });
          },
            fnDeleteWork(event, item) {
                this.confirm.require({
                    target: event.currentTarget,
                    message: 'Do you want to delete this register?',
                    icon: 'pi pi-info-circle',
                    rejectClass: 'p-1 text-sm text-white dark:text-surface-900 bg-surface-500 dark:bg-surface-400 border border-surface-500 dark:border-surface-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300 focus:ring-surface-400/50 dark:focus:ring-surface-300/50',
                    acceptClass: 'p-1 text-sm text-white dark:text-surface-900 bg-red-500 dark:bg-red-400 border border-red-500 dark:border-red-400 focus:outline-none focus:outline-offset-0 focus:ring hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300 focus:ring-red-400/50 dark:focus:ring-red-300/50',
                    rejectLabel: 'Cancel',
                    acceptLabel: 'Delete',
                    position: "topright",
                    accept: () => {
                        this.loading = true;

                        let workEntity = {
                            assetId: this.entity.assetId,
                            workId: item.workId,
                            workHours: item.workHours,
                            user: item.user
                        };

                        console.log("Delete item", workEntity);

                        assetService.removeWork(workEntity)
                            .then((data) => {
                                //this.fnShowToast('Remove Work Success', `The working hours have been removed from the ${this.typeName} '${this.assetName}'.`, ToastSeverity.WARN);
                                console.log("Work removed from Asset.", data);
                                 
                                // Update component with values. 
                              this.entity.operationalTime -= workEntity.workHours;
                              this.entity.totalOperational -= workEntity.workHours;
                                this.entity.lifetimeLeft += workEntity.workHours;
                                this.entity.lifetimeEstimated += workEntity.workHours;
                                this.entity.failureProbability = this.entity.operationalTime / this.entity.lifetimeSpan;

                                const index = this.entity.workLog.indexOf(item);
                                if (index > -1) { // only splice array when item is found
                                    this.entity.workLog.splice(index, 1);
                                }
                                console.log("index ", index);
                                this.$emit('onWorkRemoved', this.entity.name);
                                this.loading = false;
                            })
                            .catch((error) => {
                                console.log(error);
                                this.$emit('onError', 'Unexpected Error', 'An error occurred when trying to work assigned!!!', ToastSeverity.ERROR);
                                this.loading = false;
                            });
                    },
                    reject: () => {
                        console.log("Cancel... Do nothing.");
                    }
                });
            },
            fnShowToast(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 6000 });
            },
            fnShowMessage(title, message, severity) {
                this.$toast.add({ severity: severity, summary: title, detail: message, life: 30000 });
            },
            fnCapText(text) {
                return capitalizeFirstLetter(text);
            },
            fnFormatNumber(number) {
                return formatNumber(number);
            },
            fnFormatPercentage(number) {
                return formatPercentage(number);
            },
            fnFormatDate(dateValue) {
                return formatDate(dateValue);
            },
            fnFormatTextDate(textValue) {
                return formatDate(new Date(textValue));
          },
          fnGetMinDate() {
            var result = new Date(2024, 0, 1);
            if (arrayHasValue(this.entity["maintenanceLog"])) {
              // Turn your strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
              let sortedArray = this.entity.maintenanceLog
                .toSorted(function (a, b) { return new Date(b.timestamp) - new Date(a.timestamp); })
                .reverse();

              result = sortedArray[0].timestamp;
            }

            return result;
          },
    }
  };
</script>
<style scoped>
</style>

