<template>
  <h2 class="text-4xl leading-tight text-center">Project Alerts</h2>
  <div class="px-5 flex-auto">
    <dynamic-widget-component tab="project-alerts"></dynamic-widget-component>
  </div>
</template>

<script>
  import DynamicWidgetComponent from "@/components/DynamicWidgetComponent";

  import { stringHasValue } from "@/utils/utils.js";

  export default {
    name: "ProjectAlertsOnly",
    components: {
      DynamicWidgetComponent,
    },
    mounted() {
      this.$logService.save(this.$options.name);
      this.$emitter.emit("hideMenu");
      console.log("$emit - hideMenu");
    },
    created() {
      console.log("Project Alerts Only: Created");
     // console.log(this.$route.params);
      let projectId = this.$route.params.projectId;
      
      if (stringHasValue(projectId)) {
        console.log("changeProject - ID: ", projectId, " - Name: ", "");
        this.$emitter.emit("changeProject", { "id": projectId, "name": "Project" });
      }
      else {
        this.$store.dispatch("parseHash");
      }
    }
};
</script>
