import Keycloak from "keycloak-js";

const options = {
  url: "https://robetarme.icelab.cloud/keycloak/",
  clientId: "pub-client",
  realm: "vue",
  checkSso: true,
};

const keycloak = new Keycloak(options);
let authenticated = undefined;
let store = undefined;

/**
 * Initializes Keycloak, then run callback. This will prompt you to login.
 *
 * @param onAuthenticatedCallback
 */
async function init(onInitCallback) {
  try {

    authenticated = await keycloak.init({ onLoad: "login-required" });
    console.log("authenticated after Login:", authenticated);
    await onInitCallback();

  } catch (error) {
    console.error("Keycloak init failed:", error);
  }
}

/**
 * Initializes store with Keycloak user data
 *
 */
async function initStore(storeInstance) {
  try {
    store = storeInstance;
    store.initOauth(keycloak);

    // Show alert if user is not authenticated
    if (!authenticated) {
      alert("not authenticated");
    }
  } catch (error) {
    console.error("Keycloak init failed", error);
  }
}

/**
 * Logout user
 */
function logout(url) {
  authenticated = undefined;
  keycloak.logout({ redirectUri: url });
}

/**
 * Refreshes token
 */
async function refreshToken() {
  try {
    await keycloak.updateToken(480);
    return keycloak;
  } catch (error) {
    console.error("Failed to refresh token", error);
   }
}

const KeycloakService = {
  CallInit: init,
  CallInitStore: initStore,
  CallLogout: logout,
  CallTokenRefresh: refreshToken,
  keycloak,
};

export default KeycloakService;
